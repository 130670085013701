.findStudentContainer {
  display: grid;
  grid-template-rows: 1fr 8fr 1fr;
  height: 100vh;
}

.findStudentMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
}

.findStudentForm {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 80%;
  max-width: 30rem;
  row-gap: 1rem;
}
