.logo__brand {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: rgb(67, 67, 190);
  cursor: pointer;
}

.logo__brand__large {
  font-size: 2rem;
}

.logo__brand__medium {
  font-size: 1rem;
}
