.homepage {
  display: grid;
  grid-template-rows: 1fr 8fr 1fr;
  height: 100vh;
}

.homepage__main {
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
}

.findTutor{
  font-size: 2rem;
}

.findStudent__btn {
  width: 15rem;
}

.findTeacher__btn {
  width: 15rem;
}
