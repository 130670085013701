.login__header {
  padding: 2rem;
}

.login__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  padding-top: 10rem;
}

.login__form {
  padding: 2rem;
  display: flex;
}

.login__form__sendotp {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}

.login__form__mobile input {
  width: 100%;
}

.sendotp__btn {
  width: 100%;
}

.login__form__verifyotp {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.login__form__verifyotp button {
  width: 100%;
}

.login__error {
  padding: 1rem;
  text-align: center;
  color: red;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
